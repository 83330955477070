import { IBatchPaymentFile } from 'src/models/tools'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import styled from 'styled-components'
import BatchPayment from './BatchPayment'
import Modal from 'src/shared/views/Modal'
import React from 'react'
import moment from 'moment'
import { TextField } from '@material-ui/core'
import {
  useBatchPaymentFailedTransactions,
  useBatchPaymentGeneration
} from 'src/shared/hooks/tools'
import { usePermissions } from 'src/shared/hooks/usePermissions'
import { UserPermissionsEnum } from 'src/models/user'

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

interface IBatchPaymentsView {
  batchPayments: IBatchPaymentFile[]
}

const BatchPaymentsView = ({ batchPayments }: IBatchPaymentsView) => {
  /**
   * ----- Hook Initialization -----
   */

  const permissions = usePermissions()

  const [failedUploadModal, setFailedUploadModal] = React.useState(false)
  const [failedTransactionsFile, setFailedTransactionsFile] =
    React.useState<File | null>(null)

  const [generateModal, setGenerateModal] = React.useState(false)
  const [generateAfterValue, setGenerateAfterValue] =
    React.useState('2024-05-20')

  const { isLoading: genLoading, generateBatchPayment } =
    useBatchPaymentGeneration({
      onSuccess: () => setGenerateModal(false)
    })

  const { isLoading: uploadLoading, uploadFailedTransactions } =
    useBatchPaymentFailedTransactions({
      onSuccess: () => setFailedUploadModal(false)
    })

  /**
   * ----- Variables -----
   */

  const hasPaymentWritePermission = permissions.includes(
    UserPermissionsEnum.PaymentWrite
  )

  /**
   * ----- Functions -----
   */

  const generate = () => {
    generateBatchPayment(new Date(generateAfterValue).toISOString())
  }

  const submitUpload = () => {
    if (failedTransactionsFile) {
      uploadFailedTransactions(failedTransactionsFile)
    }
  }

  /**
   * ----- Render -----
   */
  return (
    <div>
      <TitleContainer>
        <h1>Batch Payments</h1>
        {hasPaymentWritePermission && (
          <div>
            <ButtonWithMargin onClick={() => setFailedUploadModal(true)}>
              Upload Failed Transactions
            </ButtonWithMargin>
            <ButtonWithMargin onClick={() => setGenerateModal(true)}>
              Generate
            </ButtonWithMargin>
          </div>
        )}
      </TitleContainer>

      {batchPayments.map((batchPayment) => (
        <BatchPayment key={batchPayment.fileId} batchPayment={batchPayment} />
      ))}

      <Modal
        isOpen={generateModal}
        handleClose={() => setGenerateModal(false)}
        title="Generate Batch Payments"
      >
        <FormContainer>
          <TextField
            name="after"
            type="date"
            label="Generate After"
            value={generateAfterValue}
            onChange={(e) => {
              // Check for valid date
              if (moment(e.target.value).isValid())
                setGenerateAfterValue(e.target.value)
            }}
            helperText="Generate batch payments for all claims after this date"
          />
          <ButtonWithMargin isLoading={genLoading} onClick={() => generate()}>
            Generate
          </ButtonWithMargin>
        </FormContainer>
      </Modal>

      <Modal
        isOpen={failedUploadModal}
        handleClose={() => setFailedUploadModal(false)}
        title="Upload Failed Transactions"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault()
            submitUpload()
          }}
        >
          <FormContainer>
            <input
              type="file"
              accept=".txt"
              onChange={(e) => {
                if (e.target.files) setFailedTransactionsFile(e.target.files[0])
              }}
            />
            <ButtonWithMargin
              disabled={!failedTransactionsFile}
              isLoading={uploadLoading}
              type="submit"
            >
              Upload
            </ButtonWithMargin>
          </FormContainer>
        </form>
      </Modal>
    </div>
  )
}

export default BatchPaymentsView
