import { IAmounts, IProgramMembership } from './program'
import { ILMSQuestion } from './learning'
import { IVerificationDetail } from './verificationTask'

export interface IUser {
  userId: string
  firstName: string
  status?: UserStatusEnum
  lastName: string
  dob: string
  preferredTimeZoneId: string
  hasAddedPrescriptions?: boolean
  programMemberships: IProgramMembership[]
  notifications: IUserNotications
  gender: GenderEnum
  phoneNumber?: string
  phoneNumbers?: string[]
  email?: string
  physicalCardRequested?: boolean
  address: IUserAddress
  createdAt: string
  skipDelay?: boolean
  referralCode?: string
}

export interface IUserBalances {
  lifetimeEarnings: IAmounts
  lifetimePayouts: IAmounts
  totalLiability: IAmounts
}

export interface IUserAddress {
  city?: string
  street?: string
  unit?: string
  postalCode: string
  province: string
  country: string
}

export enum UserPermissionsEnum {
  UserRead = 'UserRead',
  UserWrite = 'UserWrite',
  LearningModuleWrite = 'LearningModuleWrite',
  ProgramWrite = 'ProgramWrite',
  PaymentRead = 'PaymentRead',
  PaymentWrite = 'PaymentWrite',
  PLATFORM_ADMIN = 'PlatformAdmin'
}

export interface ICognitoUser {
  sub: string // user id
  email: string
  // eslint-disable-next-line
  given_name: string
  // eslint-disable-next-line
  family_name: string
  // eslint-disable-next-line
  email_verified: boolean
  identities: string
  profile: string
}

export interface ICurrentUser extends ICognitoUser {
  fullName: string
  permissions: UserPermissionsEnum[]
}

export interface IUserNotications {
  sms: boolean
}

export enum UserStatusEnum {
  Active = 'Active',
  Blocked = 'Blocked',
  Deleted = 'Deleted'
}

export interface IUserGet extends IUser {
  address: IUserAddress
}

export interface IBlockUserBody {
  reason: string
  notes?: string
}

export enum GenderEnum {
  Male = 'Male',
  Female = 'Female',
  NonBinary = 'NonBinary'
}

interface IAdminDetails {
  id: string
  name: string
}

export interface IUserNoteGet {
  id: string
  text: string
  postedAt: string
  addedByAdmin: IAdminDetails
}

export interface IUserNoteBody {
  postedAt: string
  text: string
}

type Amount = {
  amount: number
  currencyCode: string
}

type LifetimeEarnings = {
  points: string
  cash: Amount
}

export enum ProgramMembershipVerificationStatuses {
  Verified = 'VERIFIED',
  Unverified = 'UNVERIFIED',
  Rejected = 'REJECTED',
  OnHold = 'ON_HOLD'
}

export enum ProgramMembershipPaidStatus {
  Unknown = 'UNKNOWN',
  NotPaid = 'NOT_PAID',
  Pending = 'PENDING',
  Paid = 'PAID'
}

export interface IProgramMembershipPrescription {
  din: string
  prescriptionId: string
  medicationName: string
  condition: string
  verification: IVerificationDetail
}

export interface IUserProgramProgramMemberships {
  userId: string
  programId: string
  programName: string
  memberSince: string
  prescriptions: IProgramMembershipPrescription[]
  verification: IVerificationDetail
  completed: boolean
  paidStatus: ProgramMembershipPaidStatus
  endDate: string
  commonName: string
  lifetimeEarnings: LifetimeEarnings
  lastSyncAt: string
  createdAt: string
  waitListed: boolean
  currentWeek: number
  diagnosedByProfessional?: boolean
}

enum ActivityTypeEnum {
  Registered = 'Registered',
  LoggedIn = 'Logged in',
  PointsEarned = 'Points Earned',
  ProfileUpdated = 'Profile Updated',
  MessageSent = 'Message Sent',
  PointsRedeemed = 'Points Redeemed',
  PrescriptionAdded = 'Prescription Added',
  Blocked = 'Blocked',
  Unblocked = 'Unblocked'
}

type ValueOf<T> = T[keyof T]

export interface IUserActivities {
  details: string | null
  performedAt: string
  performedBy: string
  type: ValueOf<ActivityTypeEnum>
  userId: string
  userRelatedActionId: string
}

export interface IUserProgramCertificate {
  programId: string
  certificateNumber: string
  planNumber: string
  policyNumber: string
}

export interface IDirectDeposit {
  directDepositInfoId: string
  institutionNumber: string
  transitNumber: string
  accountNumber: string
  userId: string
  label?: string
}

export interface IETransfer {
  email: string
  fullName: string
}

export interface IDirectDepositData {
  label?: string
  transitNumber: string
  accountNumber: string
  institutionNumber: string
}

export interface IETransferData {
  email: string
  fullName: string
}

export enum UserClaimStatus {
  Submit = 'SUBMITTED',
  Pending = 'PENDING',
  PendingDirectDepositApproval = 'PENDING_DIRECT_DEPOSIT_APPROVAL',
  PendingVerification = 'PENDING_VERIFICATION',
  PendingProgramCompletion = 'PENDING_PROGRAM_COMPLETION',
  Resolved = 'RESOLVED',
  Rejected = 'REJECTED',
  Deleted = 'DELETED'
}

type PaymentMethod =
  | (Omit<IDirectDeposit, 'directDepositInfoId' | 'userId'> & {
      type: 'DirectDepositInfo'
    })
  | (IETransferData & { type: 'ETransferEmail' })
  | { id: string; type: 'DirectDepositAccountId' }

export interface IUserClaim {
  claimId: string
  amount: IAmounts
  certificate?: Omit<IUserProgramCertificate, 'programId'>
  createdAt: string
  paymentMethod: PaymentMethod
  images: string[]
  notes: string
  status: UserClaimStatus
  userId: string
  firstName: string
  lastName: string
  attestationContactEmail?: string
  programId: string | null
  programName: string | null
  usersUsingSameEmail:
    | { userId: string; firstName: string; lastName: string }[]
    | null
}

export interface IUserLearningModuleChosenAnswer {
  questionId: string
  indexesSelected: string[]
  score: {
    percent: number
  }
  feedback: string
}

export interface IUserLearningModuleResponses {
  question: ILMSQuestion & { id: string }
  chosenAnswer: IUserLearningModuleChosenAnswer
  recordedAt: string
  questionId: string
}
